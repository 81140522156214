.column-item {
    padding: 5px;
    height: 200px;
}

.content-column-item {
    background: #eab300;
    padding: 5px;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.content-column-item h3 {
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
}

.container-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px 0px;
}
.container-item a  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.content-icon-item {
    background: #eab300;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-bottom: 15px;
}
.container-item h3{
    color: #000;
    font-size: 12px;
    text-transform: uppercase;
}