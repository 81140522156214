.container-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
}
.container-header img {
    object-fit: contain;
    height: 250px;
    width: 100%;
}