/* .inner-scroll{
    height: 100% !important;
}

.scroll-content {
    padding-top: 0px !important;
  } */

.alert-wide .alert-wrapper{
    width: 90% !important;
    max-width: 100% !important;
}

ion-modal {
    --width: 290px;
    --height: 382px;
    --border-radius: 8px;
}

ion-modal ion-datetime {
    height: 382px;
}