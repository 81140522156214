.login-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #eab300;
}

.login-page .form-container-login {
    position: absolute;
    top: 45%;
    left: 50%;
    margin: -160px 0 0 -160px;
    width: 320px;
    height: auto;
    padding-left: 0px;
    padding-right: 15px;
    padding-top: 36px;
    padding-bottom: 36px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.18);
    background: #ffffff;
    border-radius: 12px;
}

.login-form-button {
    background: transparent;
    border: 1px solid #b9ae72;
    color: #b9ae72;
    min-width: 120px;
    font-size: 16px;
    padding: 4px 0px;
    height: 40px;
}