.container-error {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  height :250px;
  justify-content: center;
  align-items: center;
}

.container -errorstrong {
  font-size: 20px;
  line-height: 26px;
}

.container-error p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container-error a {
  text-decoration: none;
}